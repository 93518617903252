import * as React from 'react';
import cn from 'classnames';
import css from './TwoColumnHero.scss';
import Grid from 'styleguide/components/Grid/Grid';
import H1 from 'styleguide/components/Heading/H1';
import ButtonAsNativeLink from 'styleguide/components/Button/ButtonAsNativeLink';

interface Props {
  title: React.ReactNode;
  description: React.ReactNode;
  buttonText?: string;
  descriptionClass?: string;
  containerClass?: string;
  titleClass?: string;
  descriptionWrapperClass?: string;
  imageColumnClass?: string;
  heroButton?: string;
  HeroImage?: string;
  buttonLink: string;
}
const TwoColumnHero = ({
  title,
  description,
  buttonText,
  descriptionClass,
  containerClass,
  titleClass,
  descriptionWrapperClass,
  imageColumnClass,
  heroButton,
  HeroImage,
  buttonLink,
}: Props) => (
  <Grid.Container className={cn(css.container, containerClass)}>
    <Grid>
      <Grid.Row className={css.row}>
        <Grid.Col sm={12} md={6} className={cn(css.column)}>
          <div className={cn(css.descriptionWrapper, descriptionWrapperClass)}>
            <H1 className={cn(css.title, titleClass)}>{title}</H1>
            <p className={cn(css.description, descriptionClass)}>{description}</p>
            <ButtonAsNativeLink
              buttonType="link"
              color="orange"
              target={buttonLink}
              className={cn(heroButton)}
            >
              {buttonText}
            </ButtonAsNativeLink>
          </div>
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          className={cn(css.imageColumn, css.placeHolder, imageColumnClass)}
          style={{
            backgroundImage: `url(${HeroImage})`,
          }}
        />
      </Grid.Row>
    </Grid>
  </Grid.Container>
);

export default TwoColumnHero;
